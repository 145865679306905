import React from 'react';
import ArrowDownOnSquare from 'public/icons/arrow-down-on-square.svg';
import ClaimFees from 'public/icons/claim-fees.svg';
import ArrowPath from 'public/icons/arrow-path.svg';
import Deleverage from 'public/icons/deleverage.svg';
import Exit from 'public/icons/exit.svg';
import Liquidity from 'public/icons/liquidity.svg';
import { IMenuListItem } from '../../../ui-kit/MenuList/types';
import { EActionType } from './types';
// import { ComingSoonChip } from '../AutomationsModal/constants';

export const ACTIONS_LIST: Array<IMenuListItem & { type: EActionType }> = [
  {
    type: EActionType.AddLiquidity,
    icon: <Liquidity />,
    label: 'Add liquidity',
    description: 'Add funds to the existing position',
    // disabled: true,
    // endIcon: <ComingSoonChip />
  },
  {
    type: EActionType.CompoundFees,
    icon: <ArrowDownOnSquare />,
    label: 'Compound fees',
    description: 'Reinvest generated fees back to the position',
  },
  {
    type: EActionType.ClaimFees,
    icon: <ClaimFees />,
    label: 'Claim fees',
    description: 'Collect generated fees to your wallet',
    // endIcon: <ComingSoonChip />,
  },
  {
    type: EActionType.RebalancePosition,
    icon: <ArrowPath />,
    label: 'Rebalance position',
    description: 'Set new min & max price range for the position',
  },
  {
    type: EActionType.DeleveragePosition,
    icon: <Deleverage />,
    label: 'Deleverage position',
    description: 'Repay debt & deleverage existing position',
  },
  {
    type: EActionType.ClosePosition,
    icon: <Exit />,
    label: 'Close position',
    description: 'Deleverage and close existing position',
  },
];
